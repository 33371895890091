import Button from "../../../componentsShared/buttons/Button/Button";

function Test() {

    function sendEmail() {
        const mailtoLink = `mailto:info@управ-дом.рф`;
        window.location.href = mailtoLink;
    }
    return (
        <div className='main-page__test'>
            <p className='main-page__title-test'>Тестовый период</p>
            <p className='main-page__subtitle-test'>На срок до 3х месяцев, без предоплаты</p>
            <div className='main-page__btn-test'>
                <Button text={'Связаться с нами'} onClick={sendEmail} />
            </div>
        </div>)
}

export default Test;