import ContainerTwo from './componentsShared/containerTwoSection';
import imgTitle from '../../../assets/images/title/imgpsh_fullsize_anim-15.jpeg';
import Button from '../../../componentsShared/buttons/Button/Button';
function Title() {

    function sendEmail() {
        const mailtoLink = `mailto:info@управ-дом.рф`;
        window.location.href = mailtoLink;
    }

    return (
        <ContainerTwo
            isReverseColumn={true}
            one={
                <div className='left'>
                    <h1 className='main-page__title'>Управ-Дом</h1>
                    <h2 className='main-page__subtitle'>Система для управления СНТ или Коттеджным поселком</h2>
                    <div className='main-page__btn'>
                        <Button text={'Связаться с нами'} onClick={sendEmail} />
                    </div>
                </div>
            }
            two={
                <img className='main-page__img' src={imgTitle} alt="тайтл" />
            }
        />
    )
}

export default Title;