import ContainerTwo from './componentsShared/containerTwoSection';
import imgControl from '../../../assets/images/control/imgpsh_fullsize_anim-7.jpeg';

function ControlProperty() {
    return (
        <ContainerTwo
            one={
                <img className='main-page__img' src={imgControl} alt="тайтл" />
            }
            two={
                <div className='left'>
                    <h3 className='main-page__title'>Управление объектами недвижимости</h3>
                    <p className='main-page__subtitle'>Легкий и наглядный список жителей и участков</p>
                </div>
            }
        />
    )
}

export default ControlProperty;