import ContainerTwo from './componentsShared/containerTwoSection';
import imgInvoice from '../../../assets/images/invoice/imgpsh_fullsize_anim-8 — копия.jpeg';

function Invoice() {

    return (
        <ContainerTwo
            isReverseColumn={true}
            one={
                <div className='left'>
                    <p className='main-page__title'>Контроль оплат</p>
                    <p className='main-page__subtitle'>Управляйте оплатой услуг и членских взносов</p>
                </div>
            }
            two={
                <img className='main-page__img' src={imgInvoice} alt="тайтл" />
            }
        />
    )
}

export default Invoice;