import React, { useState, useEffect } from 'react';

function RemoveProfile() {

    const [locale, setLocale] = useState('ru-RU');
    const [translate, setTranslate] = useState({});

    // Получаем язык браузера
    const userLanguage = navigator.language || navigator.userLanguage;

    function changeRuLocale() {
        setLocale('ru-RU')
    }

    function changeEnLocale() {
        setLocale('en-EN')
    }

    const translateRu = {
        title: 'Как удалить профиль',
        text1: 'Откройте приложение',
        text2: 'Перейдите в настройки',
        text3: 'Нажмите кнопку Удалить Профиль',
        text4: 'Введите пароль',
        text5: 'Вы можете запросить удаление профиля, отправив запрос на info@упрв-дом.рф',
    }

    const translateEn = {
        title: 'How to Delete Profile',
        text1: 'Open the application',
        text2: 'Go to settings',
        text3: 'Click the Delete Profile button',
        text4: 'Enter your password',
        text5: 'You can also request profile deletion by sending a request to info@auto-dnevnik.ru',
    }

    useEffect(() => {
        setLocale(userLanguage)
    }, [userLanguage])

    useEffect(() => {
        if (locale.startsWith('ru')) {
            setTranslate(translateRu)
        } else {
            setTranslate(translateEn)
        }
    }, [locale])

    return (
        <div className='remove-profile'>
            {/* <>
                <button onClick={changeRuLocale}><p>Ru</p></button>
                <button onClick={changeEnLocale}><p>En</p></button>
            </> */}
            <h2>{translate.title}</h2>
            {/* <p>{translate.text1}</p>
            <p>{translate.text2}</p>
            <p>{translate.text3}</p>
            <p>{translate.text4}</p> */}
            <p>{translate.text5}</p>
        </div>
    )
}

export default RemoveProfile;