import ContainerTwo from './componentsShared/containerTwoSection';
import imgAds from '../../../assets/images/ads/imgpsh_fullsize_anim-21.jpeg';

function Ads() {

    return (
        <ContainerTwo
            one={
                <img className='main-page__img' src={imgAds} alt="тайтл" />
            }
            two={
                <div className='left'>
                    <p className='main-page__title'>Удобные объявления</p>
                    <p className='main-page__subtitle'>Создавайе объявления для жителей внутри приложения</p>
                </div>
            }
        />
    )
}

export default Ads;