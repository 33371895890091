/** Контейнер для блоков - в нем две условные секции- расположены горизонтально на широком экране и вертикаьно на узком */
function containerTwo({ one, two, isReverseColumn }) {
    return (
        <div className={`${!isReverseColumn ? 'main-page__container' : 'main-page__container-revers'}`}>
            <div className='main-page__container-item'>
                {one}
            </div>
            <div className='main-page__container-item'>
                {two}
            </div>
        </div>
    )
}

export default containerTwo;