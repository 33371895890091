import { Route, Routes } from "react-router-dom";
import MainPage from "../Pages/Main/MainPage";
import PrivacyPage from "../Pages/PrivacyPage/PrivacyPage";
import NotFoundPage from "../Pages/NotFoundPage/NotFoundPage";
import RemoveProfilу from "../Pages/RemoveProfile/RemoveProfile";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            {/* Политика обработки конфиденциальной информации */}
            <Route path="/privacy" element={<PrivacyPage />} />
            {/* Удаление профиля */}
            <Route path="/remove-profile" element={<RemoveProfilу />} />
            {/* Not found page */}
            <Route path="/*" element={<NotFoundPage />} />
        </Routes>
    )
}

export default AppRoutes;