import React, { useState, useEffect } from 'react';
import Title from './components/Title';
import ControlProperty from './components/ControlProperty';
import Invoice from './components/Invoice';
import Ads from './components/Ads';
import Test from './components/Test';

function MainPage() {

  const [isShowGallery, setIsShowGallery] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowGallery(true);
    }, 1500)
  }, [])

  return (
    <section className='main-page'>
      <Title />
      <ControlProperty />
      <Invoice />
      <Ads />
      <Test />
    </section>
  )
}

export default MainPage;